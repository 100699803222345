export const pattern = /^[\p{Letter}\p{Mark}'\s-]{2,100}$/u;
export const cityPattern = /^[\p{Letter}\p{N}\p{Mark}'\s-]{2,100}$/u;
export const cardHolder = /^[\p{Letter}\p{N}\s]{2,45}$/u;
export const telephonePattern = /^([+]\d{3}[ \s]?)?(\d{9}?)$/;
export const emailPattern =
  /^[a-zA-Z0-9][a-zA-Z0-9-.]+@(?:[a-zA-Z0-9]+[.-])+[a-zA-Z]+$/;
export const companyPattern = /^[\p{Letter}\p{N}\p{Mark}'.():,/_\s-]{2,100}$/u;
export const icoPattern = /^(\d{8})$/;
export const zipPattern = /^(\d{3}[ \s]?)(\d{2}?)$/;

export const PASSWORD_RULES = [
  {
    regex: /^.{8,20}$/,
  },
  {
    regex:
      // eslint-disable-next-line
      /((?=^[^\"`']*$)(?=.{4,})((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[#_*|:<>[\]{}\\();@&$!%\\.])).*)/,
  },
  {
    regex: /^[a-zA-Z0-9]/,
  },
];
